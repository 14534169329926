import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <InnerLayout>
    <SEO title="404: Not found" />
    <InnerWrapper>
      <section className="outer-padding-x outer-padding-y">
        <h1>404: Not Found</h1>
        <div className="max-width">
          <p>You seem to be lost! Sorry about that.</p>
          <p>Unless you want to stick around with the ghost I suggest moving on via the navigation.</p>

          <div className="Ghost">
            <div className="Ghost__head">
              <div className="Ghost__eyes"></div>
              <div className="Ghost__mouth"></div>
            </div>
            <div className="Ghost__tail">
              <div className="Ghost__rip"></div>
            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="ghost-blur" />
                <feColorMatrix
                  in="ghost-blur"
                  mode="matrix"
                  values="
                          1 0 0 0 0
                          0 1 0 0 0
                          0 0 1 0 0
                          0 0 0 16 -7"
                  result="ghost-gooey" />
              </filter>
            </defs>
          </svg>

        </div>
      </section>
    </InnerWrapper>
  </InnerLayout>
)

export default NotFoundPage
